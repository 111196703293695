import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from '../assets/images/logo.jpeg'

function Login(props) {
    const { loginAdminUser, alertMessage } = props
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (props.authUser) {
            history.push("/");
        }
    }, [props.authUser]);

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let error = {}
        if (!username) {
            error['username'] = 'Username required'
        }
        if (!password) {
            error['password'] = 'Password required'
        }
        setError(error)
        let values = {
            username,
            password,
            remember: true,
            torms: true,
        }
        loginAdminUser(values);
    };

    return (<div className="login_back" style={{ marginTop: -60 }}>
        <div className="login_front">
            <div className="login_card">
                <div className="rllogin-header">
                    <img src={logo} className="logo-login" />
                    {alertMessage && (<div role="alert" class="alert alert-danger"><span>{alertMessage}</span></div>)}
                </div>
                <form role="form" autoComplete="off">
                    <div className="login_wrapper">
                        <div className="form-group">
                            <div className="col-md-12">
                                <input name="User Name" placeholder="User Name" value={username} onChange={(e) => setUserName(e.target.value)} type="text" className="form-control  user_input" aria-required="true" aria-invalid="false" />
                                {error['username'] && (<span class="invalid-feedback" role="alert"><strong>{error['username']}</strong></span>)}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-12">
                                <input name="Password" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control  pass_input" aria-required="true" aria-invalid="false" />
                                {error['password'] && (<span class="invalid-feedback" role="alert"><strong>{error['password']}</strong></span>)}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="login_ftrmy">
                    <button type="submit" onClick={handleSubmit} className="btn btn-primary">Login <i className=" ml-2 fas fa-sign-in-alt"></i></button>
                </div>
            </div>
        </div></div>);
}


const mapStateToProps = ({ auth }) => {
    const { authUser, alertMessage } = auth;
    return { authUser, alertMessage }
};

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { authActions } = require('../stores/AuthRedex')
    return {
        ...ownProps,
        ...stateProps,
        loginAdminUser: (data) => authActions.loginAdminUser(dispatch, data),
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Login);