import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import logo from '../assets/images/logo.jpeg'

class Header extends Component {

  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount() {
    const { authUser, checkToken } = this.props;
    this.tokenInterval = setInterval(() => {
      checkToken(authUser.token)
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    const { isSignout, userSignOut } = this.props
    if (isSignout !== prevProps.isSignout) {
      if (!isSignout) {
        userSignOut()
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.tokenInterval);
  }

  render() {
    const { userSignOut } = this.props
    return (
      <header>
        <div className="headerWrapper">
          <div style={{ float: 'left', color: ' #fff', position: 'relative', zIndex: 9999, padding: '10px 0 0 10px' }}><img src={logo} style={{ maxHeight: '40px', maxWidth: '100%' }} /></div>
          <marquee id="marquee_text">Dear operator please be careful while entering results, result correction may lead the user account to be negative</marquee>
          <div className="headerRightCTA">
            <ul>
              <li className="dropdown colortheme">
                <Link to='/change-password'><i className="fas fa-lock"></i></Link>
              </li>
              <li className="dropdown colortheme">
                <a href="#" onClick={() => userSignOut()} className="dropdown-toggle"><i className="fas fa-sign"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}


const mapStateToProps = ({ auth }) => {
  const { authUser, isSignout } = auth;
  return { authUser, isSignout }
};


const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { authActions } = require('../stores/AuthRedex')
  return {
    ...ownProps,
    ...stateProps,
    userSignOut: () => authActions.userSignOut(dispatch),
    checkToken: (token) => authActions.checkToken(dispatch, token)
  }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Header);