import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import { reducer as AuthRedex } from './AuthRedex';

const config = {
    key: 'root',
    storage,
    blacklist: [
    ]
}

export default persistCombineReducers(config, {
  auth: AuthRedex,
});