import React, { useState, useEffect } from "react";
import SiteApi from '../services/SiteApi'
import { connect } from "react-redux";
import { message, Spin, Button, Modal, Form, Input } from "antd";
const FormItem = Form.Item;

function Close(props) {
    const { resultPopup, resultPopupAray, feachGames, type, auth } = props
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [run, setRun] = useState('');

    useEffect(() => {
        setVisible(true)
    }, [props.resultPopupAray]);

    useEffect(() => {
        setVisible(true)
    }, [props.resultPopup]);

    useEffect(() => {
        setVisible(true)
    }, [props.type]);

    const gamesResult = async () => {
        setLoading(true)
        console.log(resultPopupAray,resultPopup.gameId, run, password, auth.token, type);
        if (resultPopupAray) {
            for (let gameId of resultPopupAray) {
                let result = await SiteApi.fancyResultsApi(gameId, null, password, auth.token, type)
                if (result.errorCode === 0) {
                    message.success(result.errorDescription);
                    feachGames(auth.token)
                    setVisible(false)
                } else {
                    message.error(result.errorDescription);
                    feachGames(auth.token)
                }
            }
        } else if(type == 2){
            let result = await SiteApi.fancyResultsApi(resultPopup.gameId, run, password, auth.token, type)
                if (result.errorCode === 0) {
                    message.success(result.errorDescription);
                    feachGames(auth.token)
                    setVisible(false)
                } else {
                    message.error(result.errorDescription);
                    feachGames(auth.token)
                }
        } else {
            let result = await SiteApi.fancyReverseApi(resultPopup.gameId, run, password, auth.token, type)
            if (result.errorCode === 0) {
                message.success(result.errorDescription);
                feachGames(auth.token)
                setVisible(false)
            } else {
                message.error(result.errorDescription);
                feachGames(auth.token)
            }
        }
        setLoading(false)
        setPassword('')
        setRun('')
    }

    return (<Modal
        title={(resultPopup ? `${resultPopup.eventName}  ${!resultPopupAray ? ('of ' + resultPopup.marketName) : ''}` : '')}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
    >
        <div className="modelContainer"><Form>
        <FormItem label="Reverse Run">
                <Input onChange={(e) => setRun(e.target.value)} value={run} placeholder="Runs" type="text" />
            </FormItem>
            <FormItem label="Password">
                <Input.Password onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Password" type="text" />
            </FormItem>
            <FormItem>
                {loading ? <Spin /> : <Button onClick={gamesResult} type="primary" htmlType="submit">Submit</Button>}
            </FormItem>
        </Form></div>
    </Modal>)

}

const mapStateToProps = (state) => ({
    auth: state.auth.authUser
})
const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { authActions } = require('../stores/AuthRedex')
    return {
        ...ownProps,
        ...stateProps,
        feachGames: (token) => authActions.feachGames(dispatch, token),
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Close);