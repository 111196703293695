import React from 'react';
import Routes from './Routes';
import 'antd/dist/antd.css';
import './assets/css/style.css';
import './assets/css/mobile.css';
import './assets/css/scss/styles.css';

import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './stores'
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'

let store = null
const middleware = [thunk]
store = compose(applyMiddleware(...middleware))(createStore)(reducers)
let persistor = persistStore(store)

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
