import moment from 'moment'
const LibraryApi = {
    checkDate: (createdTime, days) => {
        let currentDate = new Date();
        let ts = currentDate.getTime();
        let weekDate = ts - (days * 24 * 60 * 60 * 1000);
        let compareDate = new Date(weekDate);
        let createDate = new Date(createdTime);
        if (createDate > compareDate) {
            return true
        }
        return false
    },
    getMonth(month) {
        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthNames[month]
    },
    formatDate(getDate) {
        let eventdate = getDate.split(" ");
        let changeddate = eventdate[0].replace("/","-").replace("/","-");
        changeddate = new Date(changeddate+" "+eventdate[1]);
        let date = moment.utc(changeddate, "YYYY-MM-DD HH:mm:ss").toDate();
        // console.log(changeddate);
        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();
        hour = this.checkTime(hour);
        min = this.checkTime(min);
        sec = this.checkTime(sec);
        return day + "th " + monthNames[month] + " " + hour + ":" + min + ":" + sec;
    },
    formatDateWithoutSec(getDate) {
        let date = moment.utc(getDate, "YYYY-MM-DD HH:mm:ss").toDate();
        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();
        hour = this.checkTime(hour);
        min = this.checkTime(min);
        return day + "th " + monthNames[month] + " " + hour + ":" + min;
    },
    formatTime(getDate) {
        let date = moment.utc(getDate, "YYYY-MM-DD HH:mm:ss").toDate();
        let hour = date.getHours();
        let min = date.getMinutes();
        hour = this.checkTime(hour);
        min = this.checkTime(min);
        return hour + ":" + min;
    },
    createGameIdWiseGames(gamesArray) {
        let gameIdWiseArray = {};
        for (let game of gamesArray) {
            let gameIdString = "gameid_" + game["game_id"].toString();
            gameIdWiseArray[gameIdString] = game;
        }
        return gameIdWiseArray;
    },
    createCategoryWiseGames(gameIdWiseGamesArray) {
        let categoryWiseGamesArray = {};
        let inPlayData = []
        for (let gameId in gameIdWiseGamesArray) {
            if (LibraryApi.checkInplay(gameIdWiseGamesArray[gameId]["game_start"])) {
                inPlayData.push(gameIdWiseGamesArray[gameId])
            }
        }
        let returnGamesArray = [{
            cat_name: 'InPlay',
            data: inPlayData
        }];

        // Create a game array order by category of that game
        for (let gameId in gameIdWiseGamesArray) {
            if (!(gameId === "Other")) {
                let tempCategory = gameIdWiseGamesArray[gameId]["game_type_name"];
                if (!(tempCategory in categoryWiseGamesArray)) {
                    categoryWiseGamesArray[tempCategory] = [];
                }
                categoryWiseGamesArray[tempCategory].push(gameIdWiseGamesArray[gameId]);
            } else {
                categoryWiseGamesArray["Other"] = gameIdWiseGamesArray["Other"];
            }
        }

        for (let gameCategory in categoryWiseGamesArray) {
            let gameObject = {
                cat_name: gameCategory,
                data: categoryWiseGamesArray[gameCategory]
            };
            returnGamesArray.push(gameObject);
        }

        return returnGamesArray;
    },
    subCreateCategoryWiseGames(gameIdWiseGamesArray) {
        for (let item of gameIdWiseGamesArray) {
            let subCategoryWiseGamesArray = {};
            for (let game of item.data) {
                if (game.game_name in subCategoryWiseGamesArray) {
                    subCategoryWiseGamesArray[game.game_name].push(game);
                } else {
                    subCategoryWiseGamesArray[game.game_name] = [];
                    subCategoryWiseGamesArray[game.game_name].push(game);
                }
            }
            item['subCat'] = subCategoryWiseGamesArray
        }
        return gameIdWiseGamesArray;
    },
    gameTimerCountDownBettingCheckIsOpen(time, gameType) {
        let timedetails = JSON.parse(sessionStorage.getItem('timeDetails'))
        let dateEntered = new Date(time);
        let dateEnteredDiff = new Date(dateEntered.getTime() - ((timedetails ? timedetails[gameType] : 0) * 60000))
        let now = new Date();
        let difference = dateEnteredDiff.getTime() - now.getTime();
        let differenceNew = now.getTime() - dateEnteredDiff.getTime();
        if (difference >= 0) {
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            return `Open in ${days}:${hours}:${minutes}:${seconds}`
        } else {
            return false
        }

    },
    sortByCategory(data, type) {
        let returnData = []
        if (type === 'inplay') {
            for (let item of data) {
                if (this.checkInplay(item.game_start)) {
                    returnData.push(item)
                }
            }
        } else if (type === 'coming') {
            for (let item of data) {
                if (!this.checkInplay(item.game_start)) {
                    returnData.push(item)
                }
            }
        } else {
            returnData = data
        }
        return returnData;
    },
    sortByCategoryFav(data, types) {
        let returnData = []
        let totalGames = []
        for (let g of data) {
            totalGames = [...totalGames, ...g.data]
        }
        for (let item of totalGames) {
            if (item.game_id in types) {
                returnData.push(item)
            }
        }
        return returnData;
    },
    sortByCategoryBook(data) {
        let returnData = []
        let totalGames = []
        for (let g of data) {
            totalGames = [...totalGames, ...g.data]
        }
        for (let item of totalGames) {
            if (item.book && item.book.length > 0) {
                returnData.push(item)
            }
        }
        return returnData;
    },
    listToTree: (arr) => {
        var tree = [],
            mappedArr = {},
            arrElem,
            mappedElem;

        // First map the nodes of the array to an object -> create a hash table.
        for (var i = 0, len = arr.length; i < len; i++) {
            arrElem = arr[i];
            mappedArr[arrElem.user_id] = arrElem;
            mappedArr[arrElem.user_id]['key'] = String(arrElem.user_id);
            mappedArr[arrElem.user_id]['title'] = String(arrElem.username);
            mappedArr[arrElem.user_id]['children'] = [];
        }

        for (var user_id in mappedArr) {
            if (mappedArr.hasOwnProperty(user_id)) {
                mappedElem = mappedArr[user_id];
                // If the element is not at the root level, add it to its parent array of children.
                if (mappedElem.parent_id && mappedArr[mappedElem['parent_id']]) {
                    mappedArr[mappedElem['parent_id']]['children'].push(mappedElem);
                }
                // If the element is at the root level, add it to first level elements array.
                else {
                    tree.push(mappedElem);
                }
            }
        }
        return tree;
    },
    checkInplay(time) {
        let dateEntered = moment.utc(time, "YYYY-MM-DD HH:mm:ss").toDate();
        let now = new Date();
        let difference = dateEntered.getTime() - now.getTime();
        if (difference >= 0) {
            return false
        } else {
            return true
        }
    },
    checkTv(url) {
        setTimeout(() => {
            // let tv = document.getElementById('myTV')
            // let tv = document.getElementById('liveTvContent')
            // let tv = document.getElementById('myTV').contentWindow.document.getElementById('liveTvContent')

            // console.log(tv)

        }, 2000)

        // fetch(url)
        //     .then(function (response) {
        //         console.log(url + " -> " + response.ok);
        //         return response.body;
        //     })
        //     .then(function (data) {
        //         console.log("data: ", data);
        //         this.setState({ content: data });
        //     }.bind(this))
        //     .catch(function (err) {
        //         console.log("failed to load ", url, err.stack);
        //     });
        // let tv = document.getElementById('liveTvContent')
        // console.log(tv)
    },
    showGameStartTimer(date) {
        let startDate = moment.utc(date, "YYYY-MM-DD HH:mm:ss").toDate();
        // let startDate = new Date(date);
        let currentDate = new Date();

        let differenceMilliseconds = startDate.getTime() - currentDate.getTime();
        let differenceMillisecondsAbs = Math.abs(
            startDate.getTime() - currentDate.getTime()
        );

        let hourseLeftInDecimals = differenceMilliseconds / (1000 * 60 * 60);
        let hourseLeft = Math.floor(hourseLeftInDecimals);

        let minutesLeft = Math.floor((hourseLeftInDecimals - hourseLeft) * 60);

        let hourLeftString = hourseLeft < 10 ? `0${hourseLeft}` : `${hourseLeft}`;
        let minuteLeftString =
            minutesLeft < 10 ? `0${minutesLeft}` : `${minutesLeft}`;

        let finalCountdown = `${hourLeftString}:${minuteLeftString}`;

        return finalCountdown;
    },
    checkTime(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    },
    mainBooksGenarate(newData) {
        let displayObj = {};
        let data = JSON.parse(JSON.stringify(newData))
        for (let tempItem in data.own) {
            data.own[tempItem] = this.conversionFact(data.own[tempItem])
        }
        displayObj.own = data.own
        for (let tempItem in data.parent) {
            data.parent[tempItem] = this.conversionFact(data.parent[tempItem])
        }
        displayObj.parent = data.parent
        for (let tempItem of data.users) {
            for (let tempItemSub in tempItem.selections) {
                tempItem.selections[tempItemSub] = this.conversionFact(tempItem.selections[tempItemSub])
            }
        }
        displayObj.users = data.users
        displayObj.selections = data.selections
        return displayObj;
    },
    fancyBooksGenarate(bookJson, type) {
        let displayArray = [];
        let bookObject = JSON.parse(bookJson);
        let runStart = 0;
        for (let run in bookObject) {
            let runEnd = Number(run);
            displayArray.push({
                run: `${runStart.toString()} - ${runEnd.toString()}`,
                postion: `${Number(bookObject[run]).toFixed(2)}`
            });
            runStart = runEnd + 1;
            if (type === '-10') {
                if (runStart > 100000) {
                    break;
                }
            } else {
                if (runStart > 1000) {
                    break;
                }
            }
        }
        return displayArray;
    },
    consolidateBets(data) {
        let totalConsoliId = {};
        for (let item in data) {
            if (data[item].consolidate_id in totalConsoliId) {
                totalConsoliId[data[item].consolidate_id].push([
                    data[item].stake,
                    data[item].odds
                ]);
            } else {
                totalConsoliId[data[item].consolidate_id] = [];
                totalConsoliId[data[item].consolidate_id].push([
                    data[item].stake,
                    data[item].odds
                ]);
            }
        }

        var totStake = function (arrayVal) {
            let totalstake = 0;
            for (let item of arrayVal) {
                totalstake += parseFloat(item[0]);
            }
            return totalstake;
        };

        var avgOdds = function (arrayVal) {
            let totalodds = 0;
            for (let item of arrayVal) {
                totalodds += parseFloat(item[1]) * parseFloat(item[0]);
            }
            return totalodds;
        };

        let totalConsolidata = [];
        let tempIdCheck = {};
        let newMyBets = [];
        let y = 0;
        for (let item in data) {
            if (data[item].consolidate_id in totalConsoliId) {
                if (!(data[item].consolidate_id in tempIdCheck)) {
                    totalConsolidata.push(JSON.parse(JSON.stringify(data[item])));
                    totalConsolidata[y]["stake"] = totStake(
                        totalConsoliId[data[item].consolidate_id]
                    ).toFixed(2);
                    if (data[item].type === "No" || data[item].type === "Yes") {
                        totalConsolidata[y]["odds"] = data[item].odds;
                    } else {
                        totalConsolidata[y]["odds"] = (
                            avgOdds(totalConsoliId[data[item].consolidate_id]) /
                            totalConsolidata[y]["stake"]
                        ).toFixed(2);
                    }

                    tempIdCheck[data[item].consolidate_id] = true;
                    y++;
                }
            }
        }
        return totalConsolidata;
    },
    conversionFact(data) {
        let userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
        let adminUserDetails = JSON.parse(sessionStorage.getItem('userDetailsAdmin'))
        if (userDetails && userDetails.conversion_factor) {
            let conversion_factor = userDetails ? userDetails.conversion_factor : 1
            return Number(data * conversion_factor).toFixed(2)
        }
        if (adminUserDetails && adminUserDetails.conversion_factor) {
            let conversion_factor = adminUserDetails ? adminUserDetails.conversion_factor : 1
            return Number(data * conversion_factor).toFixed(2)
        }
        return Number(data * 1).toFixed(2)
    },
    conversionFactDevite(data) {
        let userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
        let adminUserDetails = JSON.parse(sessionStorage.getItem('userDetailsAdmin'))
        if (userDetails && userDetails.conversion_factor) {
            let conversion_factor = userDetails ? userDetails.conversion_factor : 1
            return Number(data / conversion_factor)
        }
        if (adminUserDetails && adminUserDetails.conversion_factor) {
            let conversion_factor = adminUserDetails ? adminUserDetails.conversion_factor : 1
            return Number(data / conversion_factor)
        }
        return Number(data / 1)
    },
    oddsDiffCalculate(currentOdds) {
        let diff = 0;
        if (currentOdds < 2) {
            diff = 0.01;
        } else if (currentOdds < 3) {
            diff = 0.02;
        } else if (currentOdds < 4) {
            diff = 0.05;
        } else if (currentOdds < 6) {
            diff = 0.1;
        } else if (currentOdds < 10) {
            diff = 0.2;
        } else if (currentOdds < 20) {
            diff = 0.5;
        } else if (currentOdds < 30) {
            diff = 1.0;
        } else {
            diff = 2.0;
        }
        return diff;
    },
    getWeeks(val) {
        var currentStart = new Date();
        var weekstart = currentStart.getDate() - currentStart.getDay() - Number(val) - 1;
        var weekend = weekstart + 8;
        var start = new Date(currentStart.setDate(weekstart));
        var currentEnd = new Date();
        var end = new Date(currentEnd.setDate(weekend));
        return [start, end];
    },
    getDateCalc(val) {
        var current = new Date();
        var daystart = current.getDate() - current.getDay() - Number(val);
        var backDate = new Date(current.setDate(daystart));
        return backDate;
    },

    searchUsers(search_string, devId, to_expand) {
        let all_users_list = document.getElementById(devId).querySelectorAll('li[id^=usertree_user]');
        let list_to_expand = {}
        for (let i = 0; i < all_users_list.length; i++) {
            all_users_list[i].style.display = 'block'
            let parent_element = all_users_list[i]
            if (this.user_matches_with_search(all_users_list[i], search_string)) {
                while (parent_element.tagName !== 'OL') {
                    if (parent_element.className.indexOf('haschildren') !== -1) {
                        let id = parent_element.id
                        list_to_expand[id] = [true, parent_element]
                    }
                    parent_element = parent_element.parentElement
                }
            } else {
                while (parent_element.tagName !== 'OL') {
                    parent_element = parent_element.parentElement
                    if (parent_element.className.indexOf('haschildren') !== -1) {
                        let id1 = parent_element.id
                        if (!(id1 in list_to_expand)) {
                            list_to_expand[id1] = [false, parent_element]
                        }
                    }
                }
                all_users_list[i].style.display = 'none'
            }
            // console.log(all_users_list[i])
        }

        if (search_string === '') {
            for (let id_to_close in list_to_expand) {
                if (id_to_close !== 'allusers_top_element') {
                    list_to_expand[id_to_close][0] = false
                }
            }
        }

        for (let id_of_element in list_to_expand) {
            if (list_to_expand[id_of_element][0]) {
                list_to_expand[id_of_element][1].style.display = 'block'
                if (list_to_expand[id_of_element][1].className.indexOf('collapsable') === -1) {
                    if (to_expand) {
                        list_to_expand[id_of_element][1].className = list_to_expand[id_of_element][1].className.replace(/(?:^|\s)expandable(?!\S)/g, '')
                        list_to_expand[id_of_element][1].className += ' collapsable'
                        list_to_expand[id_of_element][1].firstChild.className = list_to_expand[id_of_element][1].firstChild.className.replace(/(?:^|\s)expandable-hitarea(?!\S)/g, '')
                        list_to_expand[id_of_element][1].firstChild.className += ' caret-down'
                        list_to_expand[id_of_element][1].childNodes[2].className = ' active'
                    }
                }
            } else {
                if (list_to_expand[id_of_element][1].className.indexOf('collapsable') !== -1 && id_of_element !== 'allusers_top_element') {
                    list_to_expand[id_of_element][1].className = list_to_expand[id_of_element][1].className.replace(/(?:^|\s)collapsable(?!\S)/g, '')
                    list_to_expand[id_of_element][1].className += ' expandable'
                    list_to_expand[id_of_element][1].firstChild.className = list_to_expand[id_of_element][1].firstChild.className.replace(/(?:^|\s)collapsable-hitarea(?!\S)/g, '')
                    // list_to_expand[id_of_element][1].firstChild.className += ' caret-down'
                    // list_to_expand[id_of_element][1].childNodes[2].className = ' active'
                }
            }
        }

        // refresh_all_games_count()
    },

    user_matches_with_search(li, search_string) {
        let a_tag_game = li.getElementsByTagName("span")[0]
        if (a_tag_game.textContent.toLowerCase().indexOf(search_string.toLowerCase()) !== -1) {
            return true
        } else {
            return false
        }
    },
    search(nameKey, myArray) {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return i
            }
        }
    },
    commentaryVoice(score) {
        let newValue = ''
        if (score.eventTypeId === 2) {

        } else if (score.eventTypeId === 4) {
            let checkPlay = score.score.home.highlight ? score.score.home : score.score.away
            if (checkPlay.inning1) {
                // Wickets
                if (this.oldWickets && (this.oldWickets !== checkPlay.inning1.wickets)) {
                    newValue = 'Wicket'
                }
                // Runs
                if (this.oldWickets && (this.oldRuns !== checkPlay.inning1.runs)) {
                    if (this.oldRuns + 1 === Number(checkPlay.inning1.runs)) {
                        newValue = 'One run'
                    }
                    if (this.oldRuns + 2 === Number(checkPlay.inning1.runs)) {
                        newValue = 'Double run'
                    }
                    if (this.oldRuns + 3 === Number(checkPlay.inning1.runs)) {
                        newValue = 'Three runs'
                    }
                    if (this.oldRuns + 4 === Number(checkPlay.inning1.runs)) {
                        newValue = 'Four runs four'
                    }
                    if (this.oldRuns + 6 === Number(checkPlay.inning1.runs)) {
                        newValue = 'Six runs six'
                    }
                }
                this.oldWickets = checkPlay.inning1.wickets
                this.oldRuns = Number(checkPlay.inning1.runs)
            }
        } else if (score.eventTypeId === 1) {

        }
        // console.log(newValue)
        if (this.oldValue !== newValue) {
            this.oldValue = newValue
            let musicValue
            var voices = window.speechSynthesis.getVoices()
            var resultObject = this.search("Google हिन्दी", voices)
            var resultObject1 = this.search("Hindi India", voices)
            musicValue = newValue
            if (musicValue === undefined) {
                musicValue = ""
            }
            if (window.speechSynthesis) {
                let msg = new SpeechSynthesisUtterance()
                if (resultObject !== undefined) {
                    msg.voice = voices[resultObject]
                    msg.lang = "hi-IN"
                } else if (resultObject1 !== undefined) {
                    msg.voice = voices[resultObject]
                    msg.lang = "hi_IN"
                } else {
                }
                msg.volume = 1
                msg.rate = 1
                msg.pitch = 1.2
                msg.text = musicValue
                speechSynthesis.speak(msg)
            }
        }
    }
};

export default LibraryApi;