import React, { useState, useEffect } from "react";
import SiteApi from '../services/SiteApi'
import { connect } from "react-redux";
import Result from './Result';
import Close from './Close';
import { message, Button } from "antd";

function Actions(props) {
    const { item, feachGames, auth } = props
    const [result, setResult] = useState(null);
    const [close, setClose] = useState(null);
    const [type, setType] = useState(null);

    const updateGames = async (val) => {
        if (item.open !== val) {
            let updGame = await SiteApi.updLineGamesApi(val, item.gameId, auth.token)
            if (updGame.errorCode === 0) {
                message.success(updGame.errorDescription);
                feachGames(auth.token)
            } else {
                message.error(updGame.error);
            }
        }
    }
    return (<>
        {result && (<Result resultPopup={result} />)}
        {close && (<Close resultPopup={close} type={type} />)}
        <span className="pull-right burronSet" style={{ marginTop: -6 }}>
            <Button type={item.open === 1 ? 'primary' : 'default'} disabled={item.open === 2 ? true : false} onClick={() => updateGames(1)}>Enable</Button>
            <Button type={item.open === 0 ? 'primary' : 'default'} disabled={item.open === 2 ? true : false} onClick={() => updateGames(0)}>Disable</Button>
            <Button type={item.open === 2 ? 'primary' : 'default'} disabled={item.open === 2 ? true : false} danger onClick={() => { setClose(item); setType(2) }}>Close</Button>
            <Button type={item.open === 4 ? 'primary' : 'default'} danger disabled={item.open === 2 ? false : true} onClick={() => { setClose(item); setType(4) }}>Cancel</Button>
            <Button type={item.open === 3 ? 'primary' : 'default'} disabled={item.open === 2 ? false : true} onClick={() => setResult(item)}>Result</Button>
        </span ></>)

}

const mapStateToProps = (state) => ({
    auth: state.auth.authUser
})
const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { authActions } = require('../stores/AuthRedex')
    return {
        ...ownProps,
        ...stateProps,
        feachGames: (token) => authActions.feachGames(dispatch, token),
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Actions);