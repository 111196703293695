import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch, HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from './pages/Header';
import Footer from './pages/Footer';
import Login from './pages/Login';
import Home from './pages/Home';
import Password from './pages/Password';


function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <div id="html_body" className="wrapper">
                    <Header />
                    <div className="Wrapper-main">
                        <div className="wrap_contain show-copy-right">
                            <div className="wrapper-inner">
                                <Component {...props} />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
                : <Redirect to={{ pathname: '/login' }} />}
        />
    )
}

function Routes(props) {
    return (
        <HashRouter>
            <Switch>
                <Route path='/login' component={Login} />
                <PrivateRoute authed={props.authUser ? true : false} exact path='/' component={Home} />
                <PrivateRoute authed={props.authUser ? true : false} exact path='/change-password' component={Password} />
            </Switch>
        </HashRouter>
    );
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser }
};

export default connect(mapStateToProps)(Routes);
