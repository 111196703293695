import React, { PureComponent } from "react";
import { connect } from "react-redux";
import SiteApi from '../services/SiteApi'
import LibraryApi from '../services/LibraryApi'
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import Actions from './Actions';
import Close from './Close';
import { message, Spin, Button, Table, Tooltip, Input, Popconfirm } from "antd";

const dateFormat = 'YYYY-MM-DD';
class Home extends PureComponent {

  newGamesInterval
  currentMonth = null
  m = moment(new Date(), dateFormat);
  d = this.m.get('date');
  dayBefore7 = this.m.set({ 'date': this.d - 7 });

  constructor() {
    super();
    this.state = {
      allGames: [],
      gamesList: {},
      selectGame: {},
      openGames: {},
      openeventIds: {},
      toDate: moment().format("YYYY-MM-DD"),
      fromDate: moment(this.dayBefore7).format("YYYY-MM-DD"),
      setteledFancyLoad: false,
      setteledFancy: [],
      loading: false,
      searchValues: '',
      close: null,
      type: null,
      resultPopup: null,
    }
  }

  componentDidMount() {
    this.getGames()
    this.newGamesInterval = setInterval(() => {
      this.getGames()
    }, 5000);
  }

  comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.value === current.value && other.display === current.display
      }).length === 0;
    }
  }

  async getGames() {
    const { auth, feachGames } = this.props
    feachGames(auth.token)
  }

  componentDidUpdate(prevProps) {
    const { games } = this.props;
    const { allGames } = this.state;
    if(Array.isArray(games)){
      if (games !== prevProps.games) {
        if (allGames.length !== games.length) {
          this.filterGame(games)
          this.setState({ allGames: games })
        } else {
          let checkValues = false
          for (let item of games) {
            for (let items of allGames) {
              if (item.gameId === items.gameId && item.open !== items.open) {
                checkValues = true
              }
            }
          }
          if (checkValues) {
            this.filterGame(games)
            this.setState({ allGames: games })
          }
        }
      }
    }
  }

  filterGame(allGamesNew, srcValue = null) {
    const { openeventIds } = this.state
    let tempOpeneventIds = JSON.parse(JSON.stringify(openeventIds))
    let gamesList = {}
    let selectGame = {}
    for (let item of allGamesNew) {
      selectGame[item.gameId] = item.open;
      if (item.eventId in gamesList === false) {
        let childItems = []
        for (let child of allGamesNew) {
          if (child.eventId === item.eventId) {
            if (srcValue) {
              if ((String(child.marketName).toLocaleLowerCase().search(srcValue.toLocaleLowerCase()) !== -1)) {
                childItems.push(child)
              }
            } else {
              childItems.push(child)
            }
          }
        }
        item['childItems'] = JSON.parse(JSON.stringify(childItems))
        gamesList[item.eventId] = item
      }
      if (item.eventId in tempOpeneventIds === false) {
        tempOpeneventIds[item.eventId] = [{}, false]
      }
    }
    this.setState({ gamesList, selectGame, openeventIds: tempOpeneventIds })
  }

  componentWillUnmount() {
    clearInterval(this.newGamesInterval);
  }

  showCloseGameCount(games, eventId) {
    let count = 0
    for (let item of games) {
      if (item.eventId === eventId && item.open === 2) {
        count++
      }
    }
    return count
  }

  checkOpenGames(key) {
    const { openGames } = this.state;
    let tempOpenGames = JSON.parse(JSON.stringify(openGames))
    if (key in tempOpenGames) {
      delete tempOpenGames[key]
    } else {
      tempOpenGames[key] = true
    }
    this.setState({ openGames: tempOpenGames })
  }

  selectCheckParent(parent) {
    const { openeventIds, allGames } = this.state
    let tempOpeneventIds = JSON.parse(JSON.stringify(openeventIds))
    if (tempOpeneventIds[parent][1]) {
      tempOpeneventIds[parent][1] = false
      tempOpeneventIds[parent][0] = {}
    } else {
      tempOpeneventIds[parent][1] = true
      let tempChild = {}
      for (let item of allGames) {
        if (parent === item.eventId) {
          tempChild[item.gameId] = true
        }
      }
      tempOpeneventIds[parent][0] = tempChild
    }
    this.setState({ openeventIds: tempOpeneventIds })
  }

  selectCheckChild(parent, child) {
    const { openeventIds } = this.state
    let tempOpeneventIds = JSON.parse(JSON.stringify(openeventIds))
    if (tempOpeneventIds[parent][0][child]) {
      tempOpeneventIds[parent][0][child] = false
    } else {
      tempOpeneventIds[parent][0][child] = true
    }
    this.setState({ openeventIds: tempOpeneventIds })
  }

  async updateGamesAll(eventId, val) {
    const { auth } = this.props
    const { openeventIds, allGames } = this.state
    let gameIds = ''
    for (let key in openeventIds[eventId][0]) {
      if (val === 4) {
        for (let item of allGames) {
          if (key === item.gameId && item.open === 2) {
            gameIds = gameIds + (gameIds !== '' ? ',' : '') + key
          }
        }
      } else {
        gameIds = gameIds + (gameIds !== '' ? ',' : '') + key
      }
    }
    if (gameIds !== '') {
      let updGame = await SiteApi.updLineGamesApi(val, gameIds, auth.token)
      if (updGame.errorCode === 0) {
        message.success(updGame.errorDescription);
        this.getGames()
      } else {
        message.error(updGame.error);
      }
    }
  }

  onChangeDate(date, dateString) {
    this.setState({ toDate: dateString[1], fromDate: dateString[0] })
  }

  searchFancy(values) {
    const { allGames } = this.state
    this.setState({ searchValues: values })
    this.filterGame(allGames, values)
  }

  chnageTab = async (type) => {
    const { auth } = this.props
    const { toDate, fromDate } = this.state
    clearInterval(this.newGamesInterval);
    if (type === 'active') {
      this.getGames()
      this.newGamesInterval = setInterval(() => {
        this.getGames()
      }, 5000);
    } else {
      let tempToDate = new Date(toDate)
      tempToDate.setDate(tempToDate.getDate() + 1);
      this.setState({ setteledFancyLoad: true })
      let json = await SiteApi.getSetteledFancyListApi(auth.token);
      if (json.errorCode === 1) {
        message.error(json.errorDescription);
        this.setState({ setteledFancyLoad: false })
      } else {
        this.setState({ setteledFancyLoad: false, setteledFancy: json })
      }
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          // icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <i style={{ margin: '10px 0px 0px 10px' }} className="fa fa-search" aria-hidden="true"></i>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  async reverse(record) {
    this.setState({ close: null, type: 3, resultPopup: record })
    // const { auth } = this.props
    // let updGame = await SiteApi.updLineGamesApi('2', record.gameId, auth.token)
    // if (updGame.errorCode === 0) {
    //   message.success(updGame.errorDescription);
    //   let json = await SiteApi.getSetteledFancyListApi(auth.token)
    //   if (json.errorCode === 1) {
    //     message.error(json.errorDescription);
    //     this.setState({ setteledFancyLoad: false })
    //   } else {
    //     this.setState({ setteledFancyLoad: false, setteledFancy: json })
    //   }
    // } else {
    //   message.error(updGame.error);
    // }
  }

  async closeCancel(eventId, val) {
    console.log(eventId, val);
    const { openeventIds, allGames } = this.state
    let gameIds = []
    let resultPopup = {}
    for (let key in openeventIds[eventId][0]) {
      if (val === 4) {
        for (let item of allGames) {
          if (key === item.gameId && item.open === 2) {
            gameIds.push(key)
            resultPopup = item
          }
        }
      } else {
        gameIds.push(key)
      }
    }
    if (gameIds.length > 0) {
      this.setState({ close: gameIds, type: val, resultPopup })
    }
  }

  render() {
    const { allGames, gamesList, openGames, openeventIds, setteledFancyLoad, setteledFancy, close, type, resultPopup, searchValues } = this.state;
    const columns = [
      {
        title: 'Event Name',
        dataIndex: 'eventName',
        key: 'eventName',
        ...this.getColumnSearchProps('eventName'),
        ellipsis: {
          showTitle: false,
        },
        render: date => (
          <Tooltip placement="topLeft" title={date}>
            {date}
          </Tooltip>
        ),
      },
      {
        title: 'Event Id',
        dataIndex: 'eventId',
        key: 'eventId',
        ...this.getColumnSearchProps('eventId'),
      },
      {
        title: 'Market Name',
        dataIndex: 'marketName',
        key: 'marketName',
        ...this.getColumnSearchProps('marketName'),
      },
      {
        title: 'Date',
        dataIndex: 'startTime',
        key: 'startTime',
        ...this.getColumnSearchProps('startTime'),
      },
      {
        title: 'Status',
        dataIndex: 'open',
        key: 'open',
        render: (text, record) =>
          <div>{text === 3 ? 'Setteled' : 'Cancel'}</div>
      },
      {
        title: 'Result',
        dataIndex: 'winnerSelId',
        key: 'winnerSelId',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (text, record) =>
          <Popconfirm title="Are you sure you want to reverse the result?" onConfirm={() => this.reverse(record)}><Button type={'primary'}>Reverse</Button></Popconfirm>
      },
    ];
    return (<div className="sports_box live-match">
      {resultPopup && (<Close resultPopupAray={close} resultPopup={resultPopup} type={type} />)}
      <div className="sports_match-main">
        <ul className="nav nav-tabs custom-games">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" onClick={() => this.chnageTab('active')} href="#currentFancy">Active Fancy</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" id="casino-games-link" onClick={() => this.chnageTab('settled')} data-toggle="tab" href="#oldFancy">Settled Fancy</a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="currentFancy">
            <h3 className="highlights">Click on check box at extreme left to select all fancy Or Click on + icon at right to select individual fancy</h3>
            <input className="searchFancy" placeholder="Search Fancy" value={searchValues} onChange={(e) => this.searchFancy(e.target.value)} />
            {Object.keys(gamesList).map((key, index) => <div key={index}>
              <div className="panel-title">
                <span className="pull-right burronSet" style={{ marginTop: 6 }}>
                  <Button type={'primary'} onClick={() => this.updateGamesAll(gamesList[key].eventId, 1)}>Enable</Button>
                  <Button type={'primary'} danger onClick={() => this.updateGamesAll(gamesList[key].eventId, 0)}>Disable</Button>
                  <Button danger onClick={() => this.closeCancel(gamesList[key].eventId, 2)}>Close</Button>
                  <Button type={'primary'} danger onClick={() => this.closeCancel(gamesList[key].eventId, 4)}>Cancel</Button>
                  {/* <Button danger onClick={() => this.updateGamesAll(gamesList[key].eventId, 2)}>Close</Button>
                  <Button type={'primary'} danger onClick={() => this.updateGamesAll(gamesList[key].eventId, 4)}>Cancel</Button> */}
                  <Button type="dashed" danger style={{ fontSize: 14 }} onClick={() => this.checkOpenGames(index)}>{openGames[index] ? '-' : '+'}</Button>
                </span>
                <input type="checkbox" onChange={() => this.selectCheckParent(gamesList[key].eventId)} checked={openeventIds[gamesList[key].eventId][1] ? true : false} />  {gamesList[key].eventName}
                <span className="countText">Result pending ({this.showCloseGameCount(allGames, gamesList[key].eventId)})  - {LibraryApi.formatDate(gamesList[key].startTime)}</span>
              </div>
              <ul className={"lineBoardBody " + (openGames[index] ? '' : 'none')}>
                {(gamesList[key].childItems).map((item, k) => {
                  return <li key={k} className="lineBoardBodyActive" style={{ clear: 'both', background: '#ccc', borderBottom: 'solid 2px #e8e8e8', padding: '15px 10px' }}>
                    <span style={{ color: '#000' }} className="teamName"><input onChange={() => this.selectCheckChild(item.eventId, item.gameId)} type="checkbox" checked={openeventIds[item.eventId][0][item.gameId] ? true : false} />   {item.marketName} {item.bets}</span>
                    <Actions item={item} />
                  </li>
                })}
              </ul>
            </div>)}
          </div>
          <div className="tab-pane casinotabs" id="oldFancy">
            <h3 className="highlights">Click on event to select</h3>
            <div className="card">
              <div id="tag_container" className="box-body table-responsive">
                {setteledFancyLoad ? <Spin /> : <Table className="table table-bordered" {...this.state} columns={columns} dataSource={setteledFancy} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  games: state.auth.games,
})
const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { authActions } = require('../stores/AuthRedex')
  return {
    ...ownProps,
    ...stateProps,
    userSignOut: () => authActions.userSignOut(dispatch),
    feachGames: (token) => authActions.feachGames(dispatch, token),
  }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Home);