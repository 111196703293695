import SiteApi from '../services/SiteApi'
const types = {
    SET_USER_TYPE: 'SET_USER_TYPE',
    SET_PORTRAIT: 'SET_PORTRAIT',

    TOGGLE_COLLAPSED_NAV: 'TOGGLE_COLLAPSE_MENU',
    WINDOW_WIDTH: 'WINDOW-WIDTH',
    SWITCH_LANGUAGE: 'SWITCH-LANGUAGE',
    SHOW_MESSAGE: 'SHOW_MESSAGE',
    HIDE_MESSAGE: 'HIDE_MESSAGE',
    ON_SHOW_LOADER: 'ON_SHOW_LOADER',
    ON_HIDE_LOADER: 'ON_HIDE_LOADER',
    INIT_URL: 'INIT_URL',

    FETCH_SIGNIN_PENDING: 'FETCH_SIGNIN_PENDING',
    FETCH_SIGNIN_FAILURE: 'FETCH_SIGNIN_FAILURE',
    FETCH_SIGNIN_SUCCESS: 'FETCH_SIGNIN_SUCCESS',

    FETCH_SIGNIN_ADMIN_PENDING: 'FETCH_SIGNIN_ADMIN_PENDING',
    FETCH_SIGNIN_ADMIN_FAILURE: 'FETCH_SIGNIN_ADMIN_FAILURE',
    FETCH_SIGNIN_ADMIN_SUCCESS: 'FETCH_SIGNIN_ADMIN_SUCCESS',

    FETCH_SIGNOUT_PENDING: 'FETCH_SIGNOUT_PENDING',
    FETCH_SIGNOUT_FAILURE: 'FETCH_SIGNOUT_FAILURE',
    FETCH_SIGNOUT_SUCCESS: 'FETCH_SIGNOUT_SUCCESS',

    FETCH_CHECK_TOKEN_SUCCESS: 'FETCH_CHECK_TOKEN_SUCCESS',
    CHECK_USER_ROLE_SUCCESS: 'CHECK_USER_ROLE_SUCCESS',

    FETCH_CASINO_PENDING: 'FETCH_CASINO_PENDING',
    FETCH_CASINO_FAILURE: 'FETCH_CASINO_FAILURE',
    FETCH_CASINO_SUCCESS: 'FETCH_CASINO_SUCCESS',

    FETCH_GAMES_PENDING: 'FETCH_GAMES_PENDING',
    FETCH_GAMES_FAILURE: 'FETCH_GAMES_FAILURE',
    FETCH_GAMES_SUCCESS: 'FETCH_GAMES_SUCCESS',
};

export const authActions = {
    loginAdminUser: async (dispatch, data) => {
        dispatch({ type: types.FETCH_SIGNIN_PENDING })
        const json = await SiteApi.adminLoginApi(data.username, data.password)
        if (json && json.errorCode === 0) {
            sessionStorage.setItem('userDetails', JSON.stringify(json.data));
            sessionStorage.setItem('user_fullname', JSON.stringify(json.data.username));
            dispatch({
                type: types.FETCH_SIGNIN_SUCCESS,
                isSignout: false,
                authUser: json.data,
            })
        } else {
            dispatch({
                type: types.FETCH_SIGNIN_FAILURE,
                message: json ? json.errorDesription : '',
            })
        }
    },
    checkToken: async (dispatch, token) => {
        const json = await SiteApi.checkTokenApi(token)
        if (json && json.errorCode === 1) {
            dispatch({
                type: types.FETCH_CHECK_TOKEN_SUCCESS,
                isSignout: false,
            })
        }
    },
    userSignOut: async (dispatch) => {
        dispatch({ type: types.FETCH_SIGNOUT_PENDING })
        sessionStorage.setItem('userDetails', null);
        dispatch({
            type: types.FETCH_SIGNOUT_SUCCESS,
            isSignout: true,
            timeDetails: null,
            authUser: null,
        })
    },
    hideMessage: () => {
        return {
            type: types.HIDE_MESSAGE,
        };
    },
    showAuthMessage: (message) => {
        return {
            type: types.SHOW_MESSAGE,
            payload: message
        };
    },
    setInitUrl: (url) => {
        return {
            type: types.INIT_URL,
            payload: url
        };
    },
    showAuthLoader: () => {
        return {
            type: types.ON_SHOW_LOADER,
        };
    },
    hideAuthLoader: () => {
        return {
            type: types.ON_HIDE_LOADER,
        };
    },
    feachGames: async (dispatch, token) => {
        dispatch({ type: types.FETCH_GAMES_PENDING })
        let json = await SiteApi.getLineGamesApi(token)
        if (json && json.errorCode === 1) {
            dispatch({
                type: types.FETCH_GAMES_FAILURE,
                message: json.errorDescription,
            })
        } else {
            dispatch({
                type: types.FETCH_GAMES_SUCCESS,
                games: json ? json : [],
            })
        }
    },
};

const INIT_STATE = {
    isPortrait: false,
    userType: 'isClient',
    games: [],
    userRole: {},
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    isSignout: false,
    casinoAuth: null,
    authUser: sessionStorage.getItem('userDetails') ? JSON.parse(sessionStorage.getItem('userDetails')) : null,
};

export const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.FETCH_GAMES_PENDING:
        case types.FETCH_SIGNIN_PENDING:
        case types.FETCH_SIGNIN_ADMIN_PENDING:
        case types.FETCH_SIGNOUT_PENDING:
        case types.FETCH_CASINO_PENDING:
        case types.FETCH_INACTIVE_USERS_PENDING: {
            return {
                ...state,
                loader: true,
                alertMessage: null,
                showMessage: null,
                showMessage: false,
            }
        }
        case types.FETCH_GAMES_FAILURE:
        case types.FETCH_SIGNIN_FAILURE:
        case types.FETCH_SIGNIN_ADMIN_FAILURE:
        case types.FETCH_CASINO_FAILURE:
        case types.FETCH_SIGNOUT_FAILURE: {
            return {
                ...state,
                loader: false,
                alertMessage: action.message,
                showMessage: null,
                showMessage: true,
            }
        }
        case types.SET_USER_TYPE: {
            return {
                ...state,
                userType: action.userType,
            }
        }
        case types.SET_PORTRAIT: {
            return {
                ...state,
                isPortrait: action.isPortrait,
            }
        }
        case types.FETCH_SIGNIN_ADMIN_SUCCESS: {
            return {
                ...state,
                loader: false,
                isSignout: action.isSignout,
                timeDetails: action.timeDetails,
                alertMessage: null,
                alertMessage: null,
                showMessage: false,
            }
        }
        case types.FETCH_CHECK_TOKEN_SUCCESS: {
            return {
                ...state,
                loader: false,
                isSignout: action.isSignout,
            }
        }
        case types.FETCH_SIGNIN_SUCCESS: {
            return {
                ...state,
                loader: false,
                isSignout: action.isSignout,
                authUser: action.authUser,
                timeDetails: action.timeDetails,
                alertMessage: null,
                alertMessage: null,
                showMessage: false,
            }
        }
        case types.FETCH_SIGNOUT_SUCCESS: {
            return {
                ...state,
                loader: false,
                isSignout: action.isSignout,
                timeDetails: action.timeDetails,
                authUser: action.authUser,
            }
        }
        case types.HIDE_MESSAGE: {
            return {
                ...state,
                loader: false,
                alertMessage: null,
                alertMessage: null,
                showMessage: false,
            }
        }
        case types.FETCH_CASINO_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: null,
                alertMessage: null,
                showMessage: false,
                casinoAuth: action.casinoAuth,
            }
        }
        case types.CHECK_USER_ROLE_SUCCESS: {
            return {
                ...state,
                userRole: action.userRole,
            }
        }
        case types.FETCH_GAMES_SUCCESS: {
            return {
                ...state,
                loader: false,
                alertMessage: null,
                alertMessage: null,
                showMessage: false,
                games: action.games,
            }
        }
        default:
            return state;
    }
}