import ApiClient from './api';
import Config from '../common/Config';

const Api = new ApiClient({
  baseUrl: Config.apiUrl,
});

const Apis = new ApiClient({
  baseUrl: Config.apiNewUrl,
});

const SiteApi = {

  // Login Admin Apis
  adminLoginApi: async (username, password) => {
    try {
      const response = await Api.get(`managementLogin&username=${username}&pwd=${password}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },


  // Check Token Apis
  checkTokenApi: async (token) => {
    try {
      const response = await Api.get(`check_token&token=${token}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },


  // Get Line Games Api
  getLineGamesApi: async (token) => {
    try {
      const response = await Api.get(`getFancyList&token=${token}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

  // Upd Line Games Api
  updLineGamesApi: async (value, game_id, token) => {
    try {
      const response = await Api.get(`updFancyStatus&status=${value}&gids=[${game_id}]&token=${token}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },
  // Save Result Session Api
  saveResultSessionApiNew: async (value, items, token) => {
    let params = ''
    params = params + `&game_id=${items.game_id}`
    params = params + `&token=${token}`
    if (items.type === 'cancel') {
      params = params + `&gsid=-1`
      params = params + `&run=-1`
      params = params + `&pwd=${value.password}`
    } else {
      params = params + `&gsid=${items.game_id}`
      params = params + `&run=${value.winner}`
      params = params + `&pwd=${value.password}`
    }
    try {
      const response = await Api.get(`save_result_session${params}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

  // Get Fancy Result Api
  fancyResultApi: async (gid, run, token) => {
    try {
      const response = await Api.get(`fancyResult&gid=${gid}&run=${run}&token=${token}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

  // Set Fancy Results Api
  fancyResultsApi: async (gid, run, pwd, token, type = null) => {
    let params = ''
    params = params + `?gameId=${gid}`
    params = params + `&auth=${token}`
    //params = params + `&pwd=${pwd}`
    if (run) {
      //params = params + `&run=${run}`
      params = params + `&result=${run}`
    }
    if (type) {
      params = params + `&open=${type}`
    }
    try {
      //const response = await Apis.get(`fancyResults${params}`);
      const response = await Apis.get(`setResult${params}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

  fancyReverseApi: async (gid, run, pwd, token, type = null) => {
    let params = ''
    params = params + `&gameid=${gid}`
    params = params + `&token=${token}`
    params = params + `&pwd=${pwd}`
    if (run) {
      params = params + `&run=${run}`
    }
    
    try {
      const response = await Apis.get(`reverseSetteledFancy${params}`);
      if (response.statusCode === 200) {
        console.log(response);
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

  // Get Setteled Fancy List Api
  getSetteledFancyListApi: async (token) => {
    try {
      const response = await Api.get(`getSetteledFancyList&token=${token}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

  // Change Password Api
  changePasswordApi: async (values, token) => {
    try {
      const response = await Api.get(`changePassword&pwd_old=${values.pwdold}&pwd_new=${values.pwdnew}&pwd_confirm=${values.pwdconfirm}&token=${token}`);
      if (response.statusCode === 200) {
        return response.body;
      }
      return {};
    } catch (err) {
      console.log(err);
    }
  },

};

export default SiteApi;