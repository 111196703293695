import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SiteApi from '../services/SiteApi'
import logo from '../assets/images/logo.jpeg'

function Password(props) {
    const { authUser } = props
    const [alertMessage, setAlertMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [pwdold, setPwdold] = useState();
    const [pwdnew, setPwdnew] = useState();
    const [pwdconfirm, setPwdconfirm] = useState();
    const [error, setError] = useState({});
    const history = useHistory();


    const handleSubmit = async (e) => {
        setAlertMessage('')
        if (e) {
            e.preventDefault();
        }
        let error = {}
        if (!pwdold) {
            error['pwdold'] = 'Old Password required'
        }
        if (!pwdnew) {
            error['pwdnew'] = 'New Password required'
        }
        if (!pwdconfirm) {
            error['pwdconfirm'] = 'New Confirm Password required'
        }
        if (pwdnew !== pwdconfirm) {
            error['pwdcheck'] = 'Password not match'
            setAlertMessage('Password not match')
        }
        setError(error)
        let values = {
            pwdold,
            pwdnew,
            pwdconfirm,
        }
        if (Object.keys(error).length === 0) {
            let json = await SiteApi.changePasswordApi(values, authUser.token)
            if (json.errorCode === 0) {
                setSuccessMessage(json.errorDescription);
            } else {
                setAlertMessage(json.errorDescription);
            }
        }
    };

    return (<div className="login_back" style={{ marginTop: -60 }}>
        <div className="login_front">
            <div className="login_card">
                <div className="rllogin-header">
                    <img src={logo} className="logo-login" />
                    {alertMessage && (<div role="alert" className="alert alert-danger"><span>{alertMessage}</span></div>)}
                    {successMessage && (<div role="alert" className="alert alert-success"><span>{successMessage}</span></div>)}
                </div>
                <form role="form" autoComplete="off">
                    <div className="login_wrapper">
                        <div className="form-group">
                            <div className="col-md-12">
                                <input name="pwdold" placeholder="Old Password" value={pwdold} onChange={(e) => setPwdold(e.target.value)} type="text" className="form-control  user_input" aria-required="true" aria-invalid="false" />
                                {error['pwdold'] && (<span class="invalid-feedback" role="alert"><strong>{error['pwdold']}</strong></span>)}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-12">
                                <input name="pwdnew" placeholder="New Password" value={pwdnew} onChange={(e) => setPwdnew(e.target.value)} className="form-control  pass_input" aria-required="true" aria-invalid="false" />
                                {error['pwdnew'] && (<span class="invalid-feedback" role="alert"><strong>{error['pwdnew']}</strong></span>)}
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col-md-12">
                                <input name="pwdconfirm" placeholder="Confirm Password" type="password" value={pwdconfirm} onChange={(e) => setPwdconfirm(e.target.value)} className="form-control  pass_input" aria-required="true" aria-invalid="false" />
                                {error['pwdconfirm'] && (<span class="invalid-feedback" role="alert"><strong>{error['pwdconfirm']}</strong></span>)}
                            </div>
                        </div>
                    </div>
                </form>
                <div className="login_ftrmy">
                    <button type="submit" onClick={handleSubmit} className="btn btn-primary">Login <i className=" ml-2 fas fa-sign-in-alt"></i></button>
                </div>
            </div>
        </div></div>);
}


const mapStateToProps = ({ auth }) => {
    const { authUser, alertMessage } = auth;
    return { authUser, alertMessage }
};

const mapDispatchToProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { authActions } = require('../stores/AuthRedex')
    return {
        ...ownProps,
        ...stateProps,
        loginAdminUser: (data) => authActions.loginAdminUser(dispatch, data),
    }
}
export default connect(mapStateToProps, undefined, mapDispatchToProps)(Password);